import { isPersistent, MAX_TIME } from "@/__main__/constants.mjs";
import { EVENTS, handleMessage, initEvents } from "@/__main__/ipc-core.mjs";
import mainRefs, {
  EVENTS as GAME_INTEGRATION_EVENTS,
} from "@/__main__/refs.mjs";
import { setRoute } from "@/__main__/router.mjs";
import { setVolatileKV, writeSettings } from "@/app/actions.mjs";
import eventBus from "@/app/app-event-bus.mjs";
import { GAME_SYMBOL_BY_SHORT_NAME } from "@/app/constants.mjs";
import { possibleUpdateStateValues } from "@/app/util.mjs";
import { changeLanguage } from "@/i18n/i18n.mjs";
import { showUIAcessInjectorElevationSnackbar } from "@/shared/show-elevation-snackbar.mjs";
import { devDebug, devError, devWarn } from "@/util/dev.mjs";

export default async function initIPC() {
  await initEvents;

  handleMessage(EVENTS.CHANGE_LANGUAGE, (language) => {
    changeLanguage(language);
  });

  // TODO: never hardcode event names! wrong
  handleMessage("wndAttributes", (wndAttributes) => {
    wndAttributes[isPersistent] = MAX_TIME;
    writeSettings(["wndAttributes"], wndAttributes);
  });

  handleMessage(EVENTS.OPEN_PRO_PAGE, () => {
    setRoute("/premium");
  });

  handleMessage(EVENTS.OPEN_URL, ({ pathname, search, state }) => {
    setRoute(pathname, search, state);
  });

  handleMessage(EVENTS.OVERLAY_CONFIG_UIACCESS, (name) => {
    if (typeof name !== "string")
      return devError("Invalid EVENTS.OVERLAY_CONFIG_UIACCESS", name);
    showUIAcessInjectorElevationSnackbar(GAME_SYMBOL_BY_SHORT_NAME[name]);
  });

  handleMessage(EVENTS.TELL_APP, (msg) => {
    eventBus.emit(GAME_INTEGRATION_EVENTS.EVENT_OVERLAY_BROADCAST, msg);
  });

  handleMessage(EVENTS.TELL_OVERLAY, (msg) => {
    eventBus.emit(GAME_INTEGRATION_EVENTS.EVENT_APP_BROADCAST, msg);
  });

  // This is a no-op, because we get same info from APP_SCREEN.
  handleMessage(EVENTS.APP_VISIBLE, () => {});

  handleMessage(EVENTS.APP_SCREEN, ({ focused }) => {
    setVolatileKV("isFocused", focused);
  });

  handleMessage(EVENTS.UPDATER_STATUS, ({ status }) => {
    if (possibleUpdateStateValues.includes(status)) {
      setVolatileKV("updateStatus", status);
    } else {
      devWarn(`Unknown update status: ${status}`);
    }
  });

  handleMessage(EVENTS.OTA_VERSION, async (otaVersion) => {
    if (typeof otaVersion !== "string")
      return devError("Invalid EVENTS.OTA_VERSION", otaVersion);
    const semver = (await import("semver")).default;

    // Sanity check the version, the OTA version needs to be newer.
    try {
      // The below DB key is not written before this message is sent.
      // const otaVersion = await blitzMessage(DB_READ, "currentOTAVersion");
      const { PKG_VERSION } = mainRefs;
      if (semver.lt(otaVersion, PKG_VERSION)) {
        devDebug(
          `Skipping OTA because ${otaVersion} is less than package version ${PKG_VERSION}`,
        );
        return;
      }
    } catch (e) {
      devError("Failed to read OTA version", e);
    }

    setVolatileKV("updateStatus", "ota-available");
  });
}
